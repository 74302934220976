module.exports = [{
      plugin: require('../node_modules/gatsby-source-yotpo-shopify/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"prod-open-spaces","shopifyAccessToken":"6f4d28cddc7077ed29869ce6a6da42de","yotpoAppKey":"I2XWQdePPqFqWU6Z3Q2RlotGwjgbiN2OogNMtYZR","appendDefaultObject":false,"createDefaultObject":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pattern Brands","short_name":"PB","start_url":"/","background_color":"#F0EBE5","theme_color":"#0B474B","display":"minimal-ui","icon":"src/assets/images/os-favicon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc022552799737feb85aa66ae15a2ed8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ef6e233b7e3b433bab2a0aca2eeb9b8b@o222901.ingest.sentry.io/1760576"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
